import React from "react";
import {Button, Icon} from 'react-foundation';
import DataTable from 'react-data-table-component';


class SupplementComponent extends React.Component {

render() {
    const link = 'https://tpapi.regional-services.com/Sf47Yhbv82Dfq0y/';
    
    const columns = [
        {
            name: 'Action',
            //cell: (row) => <Button raised primary onClick={(row) => this.props.onClick(row, row.ID)}>Download {row => row.ID}</Button>,
            cell: row => {
              return (<>
                  {/* <Button className="btn-outline" onClick={() => this.props.onClick(row.ID)}>Download {row.ID}</Button> */}
                  <a href={link+row.ID} className="btn41-43 btn-41" target="_blank">Download</a>
              </>);
          },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
          },
        {
            name: 'Title',
            selector: row => row.Title,
            sortable: true,
        },
        {
            name: 'File Name',
            selector: row => row.FileName,
            sortable: true,
        },
        {
            name: 'Aircraft Type',
            selector: row => row.AircraftType,
            sortable: true,
        },
        {
            name: 'Construction No.',
            selector: row => row.AircraftConstructionNumber,
            sortable: true,
        },
        {
            name: 'Type Of Supplement',
            selector: row => row.TypeOfSupplement,
            sortable: true,
        },
        {
            name: 'Revision No.',
            selector: row => row.RevisionNumber,
            sortable: true,
        },
        {
            name: 'Date Published',
            selector: row => row.TimeStamp,
            sortable: true,
        },
    ];

    const content = this.props.MenuContent;
    
    
    return (
      <>
        <DataTable
            columns={columns}
            data={content} 
            defaultSortFieldId={1}
            pagination
            
        />
      </>
    );
  }
}
export default SupplementComponent;