import React from "react";
import {Button, Icon} from 'react-foundation';
import styled from 'styled-components';
import DataTable from 'react-data-table-component';

function SupplementsTestComponent (props) {

  const link = 'https://tpapi.regional-services.com/Sf47Yhbv82Dfq0y/';

  const columns = [
    {
        name: 'Action',
        //cell: (row) => <Button raised primary onClick={(row) => this.props.onClick(row, row.ID)}>Download {row => row.ID}</Button>,
        cell: row => {
          return (<>
              {/* <Button className="btn-outline" onClick={() => this.props.onClick(row.ID)}>Download {row.ID}</Button> */}
              <a href={link+row.ID} className="btn41-43 btn-41" target="_blank">Download</a>
          </>);
      },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    {
        name: 'Title',
        selector: row => row.Title,
        sortable: true,
    },
    {
        name: 'File Name',
        selector: row => row.FileName,
        sortable: true,
    },
    {
        name: 'Aircraft Type',
        selector: row => row.AircraftType,
        sortable: true,
    },
    {
        name: 'Construction No.',
        selector: row => row.AircraftConstructionNumber,
        sortable: true,
    },
    {
        name: 'Type Of Supplement',
        selector: row => row.TypeOfSupplement,
        sortable: true,
    },
    {
        name: 'Revision No.',
        selector: row => row.RevisionNumber,
        sortable: true,
    },
    {
        name: 'Date Published',
        selector: row => row.TimeStamp,
        sortable: true,
    },
];

const content = props.MenuContent;

const TextField = styled.input`
	height: 32px;
	width: 300px;
	border-radius: 3px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 1px solid #e5e5e5;
	padding: 0 32px 0 16px;

	
`;

const ClearButton = styled(Button)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	height: 34px;
	width: 32px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
`;

//filter by ac type
const FilterComponent = ({ filterText, onFilter, onClear }) => (
	<>
		<TextField
			id="search"
			type="text"
			placeholder="Filter By Aircraft Type or Supplement Type"
			aria-label="Search Input"
			value={filterText}
			onChange={onFilter}
		/>
		<ClearButton type="button" onClick={onClear}>
			X
		</ClearButton>
	</>
);



const [filterText, setFilterText] = React.useState('');
const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
const filteredItems = content.filter(item => (item.AircraftType && item.AircraftType.toUpperCase().includes(filterText.toUpperCase())) || (item.TypeOfSupplement && item.TypeOfSupplement.toUpperCase().includes(filterText.toUpperCase())));


const subHeaderComponentMemo = React.useMemo(() => {
  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText('');
    }
  };
  return <FilterComponent 
  onFilter={e => setFilterText(e.target.value)} 
  onClear={handleClear} 
  filterText={filterText} 
  />;
}, [filterText, resetPaginationToggle]);

return <DataTable title="Supplements List" 
columns={columns} 
data={filteredItems} 
pagination 
paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
subHeader 
subHeaderComponent={subHeaderComponentMemo} 
persistTableHead 
/>;



}
export default SupplementsTestComponent;